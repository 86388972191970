<template>
  <div>
  <v-container>
    <h1>Allgemeine Geschäftsbedingungen der SMARTmed-Test GmbH, Hürth</h1>
    <h2>1. Geltung dieser Allgemeinen Geschäftsbedingungen</h2>
    <p>1.1 Diese Allgemeinen Geschäftsbedingungen gelten ausschließlich für Geschäfte mit Unternehmern i.S.d. § 13 BGB. Sie gelten nicht für Geschäfte mit Verbrauchern.</p>
    <p>
      1.2 Unsere sämtlichen Lieferungen, Leistungen und Angebote erfolgen ausschließlich aufgrund dieser Geschäftsbedingungen. Diese Geschäftsbedingungen sind Bestandteil aller Verträge, die wir mit unseren
      Vertragspartnern (nachfolgend “Besteller” genannt) über unsere Lieferungen oder Leistungen schließen.
    </p>
    <p>
      1.3 Geschäftsbedingungen des Bestellers finden keine Anwendung, auch wenn wir ihrer Geltung im Einzelfall nicht gesondert widersprechen. Selbst wenn von uns auf ein Schreiben Bezug genommen wird, das
      Geschäftsbedingungen des Bestellers oder eines Dritten enthält oder auf solche verweist, so liegt darin kein Einverständnis mit der Geltung dieser Geschäftsbedingungen.
    </p>
    <p>1.4 Diese Geschäftsbedingungen gelten auch für alle zukünftigen Geschäftsbeziehungen mit dem Besteller, auch wenn sie nicht nochmals vereinbart werden.</p>
    <h2>2. Angebot und Vertragsschluss</h2>
    <p>
      2.1 Unsere Angebote sind freibleibend und unverbindlich. Alle Verträge über unsere Lieferungen und Leistungen sowie alle sonstigen Vereinbarungen und rechtserheblichen Erklärungen bedürfen zu ihrer
      Rechtswirksamkeit unserer schriftlichen oder per E-Mail oder Telefax erfolgten Bestätigung. Das gleiche gilt für Ergänzungen und Abänderungen. Unsere Mitarbeiter sind nicht berechtigt, mündliche Nebenabreden zu
      treffen.
    </p>
    <p>2.2 Bestellungen oder Aufträge können wir innerhalb von 14 Tagen annehmen.</p>
    <p>
      2.3 An von uns abgegebenen Angeboten, Kostenvoranschlägen, von uns oder Dritten stammenden, dem Besteller zur Verfügung gestellten Zeichnungen, Abbildungen, Beschreibungen und anderen Unterlagen und Hilfsmitteln
      behalten wir uns das Eigentum oder Urheberrecht vor. Der Besteller darf diese Gegenstände ohne unsere ausdrückliche Zustimmung Dritten weder als solche noch inhaltlich zugänglich machen, noch sie bekanntgeben oder
      selbst oder durch Dritte nutzen, noch sie vervielfältigen. Er hat diese Gegenstände und eventuelle Kopien auf unser Verlangen vollständig an uns zurückzugeben, wenn sie von ihm im ordnungsgemäßen Geschäftsgang
      nicht mehr benötigt werden oder wenn Verhandlungen nicht zum Abschluss eines Vertrages führen.
    </p>
    <h2>3. Preise</h2>
    <p>3.1 Unsere Preise gelten für den in unseren Auftragsbestätigungen aufgeführten Leistungs- und Lieferungsumfang. Mehr- oder Sonderleistungen werden gesondert berechnet.</p>
    <p>3.2 Unsere Preise verstehen sich in EURO (€) zuzüglich der jeweils geltenden gesetzlichen Umsatzsteuer.</p>
    <h2>4. Ausführung von Lieferungen und Leistungen</h2>
    <p>4.1 Lieferfristen und -termine sowie Leistungsfristen und -termine gelten stets nur annähernd, es sei denn, dass eine feste Frist oder ein fester Termin vereinbart sein sollte.</p>
    <p>
      4.2 In Fällen höherer Gewalt und bei sonstigen, zum Zeitpunkt des Vertragsschlusses nicht vorhersehbaren störenden Ereignissen (z.B. Betriebsstörungen aller Art, Schwierigkeiten in der Materialbeschaffung,
      Transportverzögerungen, Streiks, rechtmäßige Aussperrungen, Mangel an Arbeitskräften, Energie oder Rohstoffen, behördliche Maßnahmen sowie Nichtlieferung, nicht richtige oder nicht rechtzeitige Belieferung durch
      unsere Lieferanten), die wir nicht zu vertreten haben und die uns die Lieferung oder Leistung wesentlich erschweren oder unmöglich machen, sind wir, sofern die Behinderung nicht nur vorübergehender Dauer ist, zum
      Rücktritt berechtigt. Bei Hindernissen vorübergehender Dauer verlängern sich die Liefer- und Leistungsfristen oder verschieben sich die Liefer- oder Leistungstermine um den Zeitraum der Behinderung zuzüglich einer
      angemessenen Anlauffrist. Soweit dem Besteller infolge der Verzögerung die Abnahme der Lieferung oder Leistung nicht zuzumuten ist, kann er durch unverzügliche schriftliche Erklärung uns gegenüber von dem Vertrag
      zurücktreten. Schadensersatzansprüche sind in diesem Falle ausgeschlossen.
    </p>
    <p>
      4.3 Dasselbe gilt bei Schwierigkeiten bei der Beschaffung von notwendigen behördlichen Genehmigungen unabhängig davon, ob es uns möglich gewesen wäre, diese Schwierigkeiten bereits bei Vertragsabschluss zu
      erkennen.
    </p>
    <p>
      4.4 Geraten wir mit unserer Lieferung oder Leistung in Verzug oder wird uns eine Lieferung oder Leistung, gleich aus welchem Grunde, unmöglich, so stehen dem Besteller Schadenersatzansprüche nur nach Maßgabe der
      Regelungen der Ziffer 6 dieser Geschäftsbedingungen zu.
    </p>
    <p>
      Erbringen wir unsere Lieferungen und/oder oder Leistungen beim Besteller, ist der Besteller verpflichtet, seinerseits alle erforderlichen Vorbereitungen für die Lieferung und/oder Leistung bei ihm zu treffen.
      Insbesondere hat er zu gewährleisten, dass:
	  <ul>
		  <li>unser Personal sofort nach Ankunft am Leistungsort mit seinen Arbeiten beginnen und diese während der vereinbarten Zeiten fortsetzen kann;</li>
		  <li>nach vorheriger Mitteilung unsererseits erforderliche Arbeiten auch außerhalb der üblichen Arbeitszeiten vorgenommen werden können, soweit dies dem Besteller zumutbar ist;</li>
		  <li>der Zugang zum Leistungsort für den Transport zu liefernder Gegenstände geeignet und bei Anlieferung frei benutzbar ist;</li>
		  <li>der Leistungsort für die Durchführung unserer Lieferungen und Leistungen geeignet ist und verschließbare Räume für Material und andere Gegenstände vorhanden sind;</li>
		  <li>alle erforderlichen Sicherheits-, Arbeitsschutz- und andere Vorsichtsmaßnahmen getroffen sind und aufrechterhalten werden und dass bei Beginn und während der Montage die gesamten benötigten Güter am richtigen Ort zur Verfügung stehen.</li>
	  </ul>
    </p>
	<p>4.6 Verstößt der Besteller gegen diese Verpflichtungen, so gilt die Regelung der Ziffer 4.2 entsprechend. Die hierdurch entstehenden Stillstands- und Wartezeiten sowie Mehrarbeit hat der Besteller gesondert zu vergüten und alle von uns erforderlich gehaltenen Mehraufwendungen zu ersetzen; ein weitergehender Schadensersatzanspruch bleibt unberührt.</p>
	<h2>5. Erfüllungsort</h2>
	<p>Erfüllungsort für alle Verpflichtungen aus dem Vertragsverhältnis ist Hürth.</p>
	<h2>6. Haftung auf Schadensersatz wegen Verschuldens</h2>
	<p>6.1 Unsere Haftung auf Schadensersatz, gleich aus welchem Rechtsgrunde, insbesondere aus Unmöglichkeit, Verzug, mangelhafter oder falscher Lieferung, positiver Vertragsverletzung, Verletzung von Pflichten bei Vertragsverhandlungen und unerlaubten Handlungen (unter Einschluss der Produzentenhaftung gegenüber dem Besteller) ist, soweit es dabei jeweils auf ein Verschulden ankommt, nach Maßgabe der folgenden Bestimmungen ausgeschlossen oder beschränkt:</p>
	<p>6.2 Im Falle leichter Fahrlässigkeit unserer Organe, gesetzlicher Vertreter, leitender Angestellter haften wir nicht, soweit es sich nicht um eine Verletzung vertragswesentlicher Pflichten handelt. Vertrags-wesentlich sind die Verpflichtung zur Erbringung den vereinbarten Leistungen sowie Beratung-, Schutz- und Obhutspflichten, die dem Schutz von Leib oder Leben von Personal des Bestellers bezwecken.</p>
	<p>6.3 Im Falle grober Fahrlässigkeit unserer nicht-leitenden Angestellten oder sonstiger Erfüllungsgehilfen haften wir nicht, soweit es sich nicht um eine Verletzung vertragswesentlicher Pflichten handelt;</p>
	<p>6.4 In allen übrigen Fällen haften wir, soweit wir für das Verschulden einzustehen haben.</p>
	<p>
		6.5 Soweit wir gemäß den Ziffern 6.1. bis 6.4. dem Grunde nach auf Schadensersatz haften, ist diese Haftung ausgeschlossen:
		<ul>
			<li>für fernliegende Schäden;</li>
			<li>für Schäden, die für uns nicht voraussehbar sind;</li>
			<li>für Schäden, die von dem Besteller beherrscht werden können. Dies gilt jedoch nicht, soweit wir wegen Vorsatzes haften.</li>
		</ul>
	</p>
	<p>6.6 Im Übrigen ist unsere Haftung im Falle einfacher Fahrlässigkeit auf das 10-fache des Entgeltes für unsere Lieferungen und/oder Leistungen beschränkt. Dies auch, wenn es sich um eine Verletzung vertragswesentlicher Pflichten handelt.</p>
	<p>6.7 Soweit in der Branche des Bestellers das für den eingetretenen Schaden ursächliche Risiko üblicherweise von diesem versichert wird, ist unsere Haftung selbst bei grobem Verschulden, nicht jedoch bei Vorsatz von Organmitgliedern oder leitenden Angestellten ausgeschlossen.</p>
	<p>6.8 Die vorstehenden Haftungsausschlüsse und -beschränkungen gelten im gleichem Umfang zugunsten unserer Organe, gesetzlicher Vertreter, leitenden und nicht-leitenden Angestellten und sonstigen Erfüllungsgehilfen.</p>
	<p>6.9 Soweit wir im Rahmen unseres Geschäftsverkehrs Auskünfte geben oder beratend tätig werden, geschieht dies unentgeltlich und unter Ausschluss jeglicher Haftung.</p>
	<p>6.10 Etwaige Ansprüche aus dem Produkthaftungsgesetz bleiben von den vorstehenden Regelungen unberührt.</p>
	<h2>7. Zahlungsbedingungen</h2>
	<p>7.1 Unsere Rechnungen sind innerhalb von 10 Tagen nach Rechnungsdatum ohne Abzug zu bezahlen, soweit nichts anderes vereinbart wird. Wird ein Skonto vereinbart, so wird dieser nicht gewährt, wenn zum Zeitpunkt der Bezahlung ein fälliger Saldo zu unseren Gunsten vorhanden ist.</p>
	<p>7.2 Gerät der Besteller in Zahlungsverzug, so hat er unsere Forderung während des Verzuges mit 9 % über dem jeweiligen Basiszinssatz zu verzinsen. Die Geltendmachung eines weiteren oder geringeren Verzugsschadens bleibt vorbehalten.</p>
	<p>7.3 Die Zurückhaltung von Zahlungen wegen oder die Aufrechnung mit Gegenansprüchen des Bestellers ist nur zulässig, wenn die Gegenansprüche unbestritten oder rechtskräftig festgestellt sind.</p>
	<p>7.4 Werden uns nach Abschluss des Vertrages Umstände bekannt, die die Kreditwürdigkeit des Bestellers wesentlich zu mindern geeignet sind, sind wir berechtigt, noch ausstehende Lieferungen oder Leistungen nur gegen Vorauszahlung oder Sicherheitsleistung auszuführen oder zu erbringen.</p>
	<h2>8. Schlussbestimmungen</h2>
	<p>8.1 Gerichtsstand für alle etwaigen Streitigkeiten aus der Geschäftsbeziehung zwischen uns und dem Besteller ist nach unserer Wahl Hürth oder der Sitz des Bestellers. Für Klagen gegen uns ist Hürth ausschließlicher Gerichtsstand. Die gesetzlichen Bestimmungen über ausschließliche Gerichtsstände bleiben von dieser Regelung unberührt.</p>
	<p>8.2 Die Beziehungen zwischen uns und dem Besteller unterliegen ausschließlich dem Recht der Bundesrepublik Deutschland. Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf vom 11. April 1980 gilt nicht.</p>
	<p>8.3 Sind oder werden einzelne Bestimmungen dieser Geschäftsbedingungen ganz oder teilweise unwirksam, so wird dadurch die Wirksamkeit des übrigen Teils bzw. der übrigen Bestimmungen nicht berührt. Anstelle der unwirksamen Bestimmung bzw. des unwirksamen Teils der Bestimmung gilt diejenige rechtlich wirksame Regelung, die dem mit der unwirksamen Bestimmung verfolgten Zweck am nächsten kommt.</p>
  	</v-container>
  </div>
</template>

<script>
export default {};
</script>
